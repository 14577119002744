import BCard from "components/B/card";
import React, { useCallback, useMemo } from "react";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { CommonService, FinanceService } from "api/axios";
import { ChevronLeft } from "react-bootstrap-icons";
import BStatus from "components/B/status";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { openAlert } from "store/features/alert/AlertSlice";
import moment from "moment";
import { Loading, LocalDialog, MTable, Spacer } from "components";
import { formatComma, formatIDR, handleResponseFile } from "helper";
import CreateInvoice from "./createInvoice";
import { Button, FormGroup, Input } from "reactstrap";
import { getRouteName, routeMapping } from "routes";
import { MuiFileInput } from "mui-file-input";
import { LoadingButton } from "@mui/lab";
import { Formik, Form } from "formik";

const doColumns = [
	{
		name: "number",
		label: "No. DO",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "SO",
		label: "SKU",
		options: {
			filter: false,
			sort: false,
			customBodyRender: (value: any) => {
				return value?.PoProd?.ReqProd?.SKU?.sku;
			}
		}
	},
	{
		name: "quantity",
		label: "Kuantitas",
		options: {
			filter: false,
			sort: false,
			customBodyRender: (value: any) => {
				return formatComma(`${value} M³`);
			}
		}
	},
	{
		name: "SO",
		label: "Harga Satuan",
		options: {
			filter: false,
			sort: false,
			customBodyRender: (value: any) => {
				return "Rp " + formatIDR(value?.PoProd?.ReqProd?.offeringPrice);
			}
		}
	},
	{
		name: "SO",
		label: "Total Harga",
		options: {
			filter: false,
			sort: false,
			customBodyRender: (value: any, data: any) => {
				const quantity = data?.tableData[data.rowIndex]?.quantity;
				const price = value?.PoProd?.ReqProd?.offeringPrice;
				const totalPrice = quantity * price;
				return "Rp " + formatIDR(totalPrice);
			}
		}
	},
	{
		name: "date",
		label: "Tanggal",
		options: {
			filter: false,
			sort: false
		}
	}
];
const depositColumns = [
	{
		name: "number",
		label: "No. Deposit",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "date",
		label: "Tanggal Diterima",
		options: {
			filter: false,
			sort: false
			// customBodyRender: (value: any) => {
			// 	return formatComma(`${value} M³`);
			// }
		}
	},
	{
		name: "totalAmount",
		label: "Total (IDR)",
		options: {
			filter: false,
			sort: false,
			customBodyRender: (value: any) => {
				return "Rp " + formatIDR(value);
			}
		}
	},
	{
		name: "remainingAmount",
		label: "Available (IDR)",
		options: {
			filter: false,
			sort: false,
			customBodyRender: (value: any) => {
				return "Rp " + formatIDR(value);
			}
		}
	},
	{
		name: "paymentAmount",
		label: "Used (IDR)",
		options: {
			filter: false,
			sort: false,
			customBodyRender: (value: any) => {
				return "- Rp " + formatIDR(value);
			}
		}
	}
];
const additionalProductsColumns = [
	{
		name: "name",
		label: "Tambahan Biaya"
	},
	{
		name: "price",
		label: "Harga",
		options: {
			filter: false,
			sort: false,
			customBodyRender: (value: any) => {
				return "Rp " + formatIDR(value);
			}
		}
	}
];

const VehicleDetail = () => {
	const location = useLocation();
	const permission = useAppSelector((state) => state.user.permission);
	const invoicePermission = useMemo(() => {
		const routeName = getRouteName(location.pathname, routeMapping);

		if (routeName) {
			return permission[routeName];
		}
		return {};
	}, [location, permission]);
	const navigate = useNavigate();
	const params = useParams();
	const id = params?.id;
	const [projectDetails, setProjectDetails] = React.useState<any[]>([]);
	const [custDetails, setCustDetails] = React.useState<any[]>([]);
	const [doList, setDoList] = React.useState<DeliveryOrder[]>([]);
	const [title, setTitle] = React.useState<string>("");
	const [status, setStatus] = React.useState<string>("");
	const [files, setFiles] = React.useState<any[]>([]);
	const [uploadFile, setUploadFile] = React.useState<File>();
	const [picPayload, setPicPayload] = React.useState<
		PayloadCreatePIC & { id: string }
	>();

	const dispatch = useAppDispatch();

	const { isLoading, refetch, data } = useQuery<ApiOneInvoice, ApiError>(
		["query-vehicle"],
		async () => {
			return await FinanceService.getOnInvoice(id ?? "");
		},
		{
			enabled: true,
			onSuccess: (res) => {
				setTitle(res.data.number);
				setStatus(res?.data?.status);
				setDoList(
					res.data.DeliveryOrders.map((el) => ({
						...el,
						date: moment(el.date).format("DD MMM YYYY")
					}))
				);
				// const picPayload = {
				// 	picName: res?.data?.Project.Customer?.Pic?.name
				// }

				setPicPayload(
					res?.data?.Project.Customer?.Pic as PayloadCreatePIC & {
						id: string;
					}
				);

				try {
					const projectDetails = [
						{
							name: "Jumlah Tagihan",
							type: "money",
							data: formatIDR(res.data.total)
						},
						// {
						// 	name: "Jumlah Terbayar",
						// 	type: "money",
						// 	data: formatIDR(res.data.amountPaid)
						// },
						// {
						// 	name: "Jumlah Terhutang",
						// 	type: "money",
						// 	data: formatIDR(res.data.amountDue)
						// },
						{
							name: "Metode Pembayaran",
							type: "text",
							data:
								res?.data?.accurateData?.paymentTerm?.name ??
								"-"
						},
						{
							name: "Jatuh Tempo",
							data: `${
								res?.data?.accurateData?.paymentTerm?.netDays ??
								0
							} Hari`
						},
						{
							name: "Syarat Pembayaran",
							data: res?.data?.paymentCondition ?? "-"
						},
						{
							name: "Tanggal Tagihan",
							data: res.data?.issuedDate
								? moment(res.data.issuedDate).format(
										"DD MMM YYYY"
								  )
								: "-"
							//transDateView
						},
						{
							name: "Tanggal Jatuh Tempo",
							data: res.data?.dueDate
								? moment(
										res.data?.dueDate
								  ).format("DD MMM YYYY")
								: " - "
						},
						{
							name: "Tanggal Terkirim",
							data: res.data.deliveredDate
								? moment(res.data.deliveredDate).format(
										"DD MMM YYYY"
								  )
								: "-"
						},
						{
							name: "Tanggal Pelanggan Terima",
							data: res.data.customerReceivedDate
								? moment(res.data.customerReceivedDate).format(
										"DD MMM YYYY"
								  )
								: "-"
						},
						{
							name: "Catatan",
							data: res.data.notes ?? "-"
						},
						{
							name: "Lewat Jatuh Tempo",
							data: `${res.data.dueDateDifference ?? 0} Hari`
						},
						{
							name: "Metode Pengiriman",
							data: res.data.shippingMethod ?? "-"
						},
						{
							name: "Nomor Resi",
							data: res.data.resiNumber ?? "-"
						},
						{
							name: "Tanggal Notifikasi Upload File",
							data: res.data.notifiedUploadFileAt
								? moment(res.data.notifiedUploadFileAt).format(
										"DD MMM YYYY"
								  )
								: "-"
						},
						{
							name: "Tanggal Notifikasi Jatuh Tempo",
							data: res.data.notifiedDueDateAt
								? moment(res.data.notifiedDueDateAt).format(
										"DD MMM YYYY"
								  )
								: "-"
						}
					];
					setProjectDetails(projectDetails);
				} catch (e) {
					//
				}
				const files =
					res?.data?.InvoiceFiles?.map((file, index) => {
						return {
							name: file.File.name,
							type: "file",
							data: file.File
						};
					}) ?? [];
				setFiles(files);
				try {
					const custDetails = [
						{
							name: "Nama Pelanggan",
							type: "text",
							data: res.data.Project?.Customer?.displayName
						},
						{
							name: "No NPWP",
							type: "text",
							data: res.data.Project?.Customer?.npwp ?? "-"
						},
						{
							name: "NIK",
							type: "text",
							data: res.data.Project?.Customer?.nik ?? "-"
						},
						{
							name: "Nama PIC",
							type: "text",
							data: res.data.Project?.Customer?.Pic?.name ?? "-"
						},
						{
							name: "Jabatan PIC",
							type: "text",
							data:
								res.data.Project?.Customer?.Pic?.position ?? "-"
						},
						{
							name: "No PIC",
							data: res.data.Project?.Customer?.Pic?.phone ?? "-"
						},
						{
							name: "Alamat Penagihan",
							type: "address",
							data: {
								line1: res.data.Project?.Customer
									?.BillingAddress?.line1,
								line2: res.data.Project?.Customer
									?.BillingAddress?.line2,
								lat: res.data.Project?.Customer?.BillingAddress
									?.lat,
								lon: res.data.Project?.Customer?.BillingAddress
									?.lon
							}
						}
					];
					setCustDetails(custDetails);
				} catch (e) {
					//
				}
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const { mutate: sendFile } = useMutation<
		ApiResponseFiles,
		ApiError,
		FormData,
		ApiError
	>(async (_files: FormData) => {
		return await CommonService.uploadFile(_files);
	});

	const { mutate, isLoading: loadingUploadFile } = useMutation(
		async (_files: any) => {
			return await FinanceService.uploadFileInvoice(id as string, _files);
		},
		{
			onSuccess: (response) => {
				dialogUpload.onCloseUpload();
				refetch();
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const { mutateAsync: updatePic } = useMutation(
		async (data: PayloadCreatePIC) => {
			const res = await CommonService.updatePIC(
				picPayload?.id as string,
				data
			);

			return res;
		}
	);

	const { mutateAsync: sendNotified } = useMutation(async () => {
		const res = await FinanceService.sendNotified(id as string);
		return res;
	});

	// create
	const [localDialog, setLocalDialog] = React.useState({
		isOpen: false,
		onClose: () => setLocalDialog({ ...localDialog, isOpen: false }),
		onOpen: () => setLocalDialog({ ...localDialog, isOpen: true })
	});
	const [dialogUpload, setDialogUpload] = React.useState({
		modalUpload: false,
		onCloseUpload: () =>
			setDialogUpload((prevState) => ({
				...prevState,
				modalUpload: false
			})),
		onOpenUpload: () =>
			setDialogUpload((prevState) => ({
				...prevState,
				modalUpload: true
			}))
	});
	const [dialogNotif, setDialogNotif] = React.useState({
		modalNotified: false,
		onCloseNotified: () =>
			setDialogNotif((prevState) => ({
				...prevState,
				modalNotified: false
			})),
		onOpenNotified: () =>
			setDialogNotif((prevState) => ({
				...prevState,
				modalNotified: true
			}))
	});

	const onSubmit = () => {
		if (!uploadFile) {
			dispatch(
				openAlert({
					body: "Mohon untuk memilih file terlebih dahulu",
					color: "danger"
				})
			);
			return;
		}
		const _sendFile = new FormData();
		_sendFile.append("photos", uploadFile as File);
		_sendFile.append("name", "INVOICE_BRIK");
		sendFile(_sendFile, {
			onSuccess: (res) => {
				// const data = handleResponseFile(res.data, [uploadFile as File]);

				const payload = {
					fileId: res?.data[0]?.id,
					type: "INVOICE_BRIK"
				};

				mutate(payload);
				setUploadFile(undefined);
				dispatch(
					openAlert({
						body: "Berhasil Upload Invoice",
						color: "success"
					})
				);
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		});
	};

	const [loadingCreate, setLoadingCreate] = React.useState(false);

	const handleCreateLoading = (isLoading: boolean) => {
		setLoadingCreate(isLoading);
	};

	if (isLoading || loadingCreate || loadingUploadFile) {
		return <Loading />;
	}

	return (
		<>
			<div className="p-4 pt-6">
				<div
					className="d-flex flex-row align-items-center mb-2"
					style={{
						justifyContent: "space-between"
					}}
				>
					<div className="d-flex flex-row align-items-center">
						<div
							className="b-back-btn border mr-3"
							onClick={() => {
								navigate(-1);
							}}
						>
							<ChevronLeft />
						</div>
						<h1 className="mb-0 mr-2">{title}</h1>
						<BStatus>{status}</BStatus>
					</div>
					{invoicePermission && invoicePermission.update ? (
						<Button
							style={{
								justifySelf: "flex-end",
								alignSelf: "end",
								placeSelf: "flex-end"
							}}
							onClick={() => {
								localDialog.onOpen();
							}}
							size="sm"
							color="warning"
							className="confirm-button approve-button"
						>
							Edit
						</Button>
					) : null}

					<div
						className="d-flex flex-row align-items-center mb-2"
						style={{
							justifyContent: "space-between"
						}}
					>
						<Button
							size="sm"
							// style={{ marginLeft: "470px" }}
							color="danger"
							className="confirm-button approve-button"
							// onClick={() => handleButton()}
							// disabled={!data?.data?.InvoiceFiles?.length}
							onClick={() => {
								dialogNotif.onOpenNotified();
							}}
						>
							Kirim Notifikasi
						</Button>
						<Button
							size="sm"
							// style={{ marginLeft: "470px" }}
							color="success"
							className="confirm-button approve-button"
							onClick={() => {
								dialogUpload.onOpenUpload();
							}}
						>
							Upload Invoice
						</Button>
					</div>
				</div>
				<div className="d-flex flex-column flex-lg-row">
					<div
						className="d-flex flex-column mb-3"
						style={{ flex: 0.7 }}
					>
						<BCard
							title="Ringkasan Tagihan"
							data={projectDetails}
						/>
						<div className="mt-4">
							<MTable
								tableTitle=""
								columns={doColumns}
								tableData={doList}
								currentPage={1}
								rowsPerPage={100}
								rowsPerPageOptions={[10, 15, 100]}
								totalItems={doList?.length as number}
								selectableRowsHideCheckboxes={true}
								searchPlaceholder="Cari berdasarkan no tagihan atau nama pelanggan"
								isSearchVisible={false}
							/>
						</div>
						<div className="mt-4">
							<MTable
								tableTitle=""
								columns={additionalProductsColumns}
								tableData={data?.data?.AdditionalProducts || []}
								currentPage={1}
								rowsPerPage={100}
								rowsPerPageOptions={[10, 15, 100]}
								totalItems={
									data?.data?.AdditionalProducts
										?.length as number
								}
								selectableRowsHideCheckboxes={true}
								searchPlaceholder="Cari berdasarkan no tagihan atau nama pelanggan"
								isSearchVisible={false}
							/>
						</div>
						<div className="mt-4">
							<MTable
								tableTitle=""
								columns={depositColumns}
								tableData={
									data?.data.accurateData?.deposits?.map(
										(deposit: any) => {
											return {
												...deposit,
												remainingAmount:
													deposit.remainingAmount +
													deposit.paymentAmount
											};
										}
									) || []
								}
								currentPage={1}
								rowsPerPage={100}
								rowsPerPageOptions={[10, 15, 100]}
								totalItems={
									data?.data.accurateData?.deposits
										?.length as number
								}
								selectableRowsHideCheckboxes={true}
								searchPlaceholder="Cari berdasarkan no tagihan atau nama pelanggan"
								isSearchVisible={false}
								options={{
									onRowClick(rowData, rowMeta) {
										console.log(rowData, "rowData");

										// const id =
										// 	invoiceList[rowMeta.rowIndex]?.id;
										// // navigate(`/order/delivery-orders/${item.id}`);/
										// navigate(`/finance/invoice/${id}`);
									}
								}}
							/>
						</div>
					</div>
					<div className="spacer-v" />
					<div className="d-flex flex-column" style={{ flex: 0.3 }}>
						<BCard title="Rincian Pelanggan" data={custDetails} />
						<BCard className="mt-3" title="Dokumen" data={files} />
					</div>
				</div>
			</div>

			<CreateInvoice
				localDialog={localDialog}
				setLocalDialog={setLocalDialog}
				refetch={refetch}
				handleCreateLoading={handleCreateLoading}
				data={data?.data}
			/>

			<LocalDialog
				isOpen={dialogUpload.modalUpload}
				backdrop={"static"}
				header={"Upload Invoice"}
				onClose={() => {
					dialogUpload.onCloseUpload();
				}}
			>
				<MuiFileInput
					onChange={(newFile) => {
						if (newFile && !Array.isArray(newFile)) {
							setUploadFile(newFile);
						} else {
							setUploadFile(undefined);
						}
					}}
					value={uploadFile}
					placeholder="Insert a file"
					multiple={false}
					label="Pilih File"
					inputProps={{
						accept: "application/pdf, image/png, image/jpeg"
					}}
				/>
				<Spacer size="large" />
				<div className="modal-custom-footer">
					<LoadingButton
						color="error"
						onClick={() => {
							setUploadFile(undefined);
							localDialog.onClose();
						}}
						loading={loadingUploadFile}
						disabled={loadingUploadFile}
					>
						<span style={{ textTransform: "capitalize" }}>
							Batal
						</span>
					</LoadingButton>
					<LoadingButton
						color="error"
						variant="contained"
						onClick={onSubmit}
						loading={loadingUploadFile}
						disabled={loadingUploadFile}
						sx={{ backgroundColor: "red", color: "white" }}
					>
						<span style={{ textTransform: "capitalize" }}>
							Upload
						</span>
					</LoadingButton>
				</div>
			</LocalDialog>

			<LocalDialog
				isOpen={dialogNotif.modalNotified}
				backdrop={"static"}
				header={"Kirim Notifikasi"}
				onClose={() => {
					dialogNotif.onCloseNotified();
				}}
			>
				<p>
					{
						"Apakah anda yakin ingin mengirim notifikasi file invoice "
					}
					<strong>{data?.data?.number || "-"}</strong>
					{" ke "}
					<strong>
						{data?.data?.Project?.Customer?.Pic?.name ||
							"pelanggan"}
					</strong>
					{data?.data?.Project?.Customer?.Pic?.phone ? (
						<>
							{" / "}
							<strong>
								{data?.data?.Project?.Customer?.Pic?.phone}
							</strong>
						</>
					) : (
						""
					)}
					?
				</p>
				<p>
					{data?.data?.notifiedUploadFileAt ? (
						<>
							Notifikasi sudah pernah dikirim pada{" "}
							<span style={{ color: "red", fontWeight: "bold" }}>
								{moment(
									data?.data?.notifiedUploadFileAt
								).format("DD MMMM YYYY")}{" "}
								jam:{" "}
								{moment(
									data?.data?.notifiedUploadFileAt
								).format("HH:mm")}
							</span>
						</>
					) : (
						"Belum ada notifikasi yang dikirim sebelumnya."
					)}
				</p>
				<Formik
					initialValues={{
						name: picPayload?.name,
						phone: picPayload?.phone
					}}
					onSubmit={async (values, { setSubmitting }) => {
						setSubmitting(true);
						try {
							if (
								values.name !== picPayload?.name ||
								values.phone !== picPayload?.phone
							) {
								const payloadPic = {
									name: values.name,
									phone: values.phone
									// ...picPayload
								};
								await updatePic(payloadPic as PayloadCreatePIC);
							}
							await sendNotified();

							dispatch(
								openAlert({
									body: "berhasil mengirim notifikasi",
									color: "success"
								})
							);
							dialogNotif.onCloseNotified();
							refetch();
						} catch (error) {
							dispatch(
								openAlert({
									body: "Gagal mengirim notifikasi",
									color: "danger"
								})
							);
						}
					}}
				>
					{({
						// errors,
						handleChange,
						isSubmitting,
						values
						// setFieldValue
					}) => {
						return (
							<Form>
								<div style={{ marginBottom: "20px" }}>
									<label>Nama PIC</label>
									<Input
										name="name"
										value={values.name}
										onChange={handleChange}
									/>
									<label style={{ marginTop: "10px" }}>
										No PIC
									</label>
									<Input
										name="phone"
										value={values.phone}
										onChange={handleChange}
									/>
								</div>

								<div className="modal-custom-footer">
									<LoadingButton
										color="error"
										onClick={() => {
											localDialog.onClose();
											setTimeout(() => {
												dialogNotif.onCloseNotified();
											});
										}}
										loading={isSubmitting}
										disabled={isSubmitting}
									>
										<span
											style={{
												textTransform: "capitalize"
											}}
										>
											Batal
										</span>
									</LoadingButton>
									<LoadingButton
										color="error"
										variant="contained"
										type="submit"
										loading={isSubmitting}
										disabled={isSubmitting}
										sx={{
											backgroundColor: "red",
											color: "white"
										}}
									>
										<span
											style={{
												textTransform: "capitalize"
											}}
										>
											Kirim Notifikasi
										</span>
									</LoadingButton>
								</div>
							</Form>
						);
					}}
				</Formik>
			</LocalDialog>
		</>
	);
};

export default VehicleDetail;
