import {
	AccurateService,
	CommonService,
	InventoryService,
	OrderService
} from "api/axios";
import { LoadingSpinner, LocalDialog, Spacer } from "components";
import BForm from "components/B/form";
import { useListSearchParams } from "hooks";
import React, {
	ChangeEvent,
	Dispatch,
	SetStateAction,
	useEffect,
	useState
} from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import ReactSelect, {
	components,
	OptionProps,
	SingleValue
} from "react-select";
import Creatable from "react-select/creatable";
import { Col, Input, Row } from "reactstrap";
import { openAlert } from "store/features/alert/AlertSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useDebounce } from "use-debounce";
import styles from "./modalCreateDo.module.scss";
import BDropdown from "components/B/dropdown";
import { formatDateID } from "helper";
//isAccurateDoDisabled

interface AccurateWarehouse {
	scrapWarehouse: boolean;
	defaultWarehouse: boolean;
	locationId: number;
	optLock: number;
	name: string;
	description: null;
	pic: null;
	id: number;
	suspended: boolean;
}
interface AccurateBranch {
	defaultBranch: boolean;
	name: string;
	id: number;
	suspended: boolean;
}

interface PayloadDO {
	scheduleId?: {
		number: string;
		id: string;
	};
	date?: string;
	quantity?: number;
	sealNumber?: string;
	licenseNumber?: number;
	number?: string;
	driver?: {
		id: string;
		fullName: string;
	};
	truck?: {
		id: string;
		plate_number: string;
		labelStr: string;
		valueStr: string;
	};
	mixDesignId?: string;
	inputSku?: string;
	mixDesign?: {
		id: string;
		name: string;
	};
	inputWoNumber?: string;
	isSameSeal?: boolean;
	customerAccurate?: SingleValue<CustomerAccurate>;
	addressAccurate?: {
		valueStr: number | null;
		labelStr: string;
	} | null;
	addressAccurateText?: string;
	volumeKumulatifAccurate?: number;
	biayaMobilisasiAccurate?: number;
	biayaTolAccurate?: number;
	jarakPengirimanAccurate?: number;
	saleOrderAccurate?: SingleValue<SaleOrderAccurate>;
	saleOrderItem?: SingleValue<DetailItem>;
	loadingStartTime?: string;
	loadingEndTime?: string;
	driverAccurate?: {
		valueStr: string;
		labelStr: string;
	};
	dispatcher?: {
		valueStr: string;
		labelStr: string;
	};
	actualDriver?: SingleValue<
		ActualDrivers & { label: string; value: string; __isNew__: true }
	>;
	warehouse?: SingleValue<AccurateWarehouse>;
	branch?: SingleValue<AccurateBranch>;
}

interface CustomerAccurate {
	mobilePhone: string;
	name: string;
	id: number;
	whatsappNo: string;
	customerNo: string;
	email: string;
}

interface Dispatchers {
	id: string;
	name: string;
}

interface ProjectRoute {
	id: string;
	batchingPlantId: string;
	projectId: string;
	tollFee: number;
}

export default function ModalCreateDO({
	isOpen,
	setIsOpen,
	refetch,
	idWOSelected,
	skuSelected,
	deliveryOrders,
	customerName,
	customerAccurateId,
	soAccurateId,
	addressAccurate,
	customerAccurateName,
	defaultCustomer,
	route,
	scheduleBpId
}: {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	refetch: () => void;
	idWOSelected: {
		id: string;
		number: string;
		date: Date;
		quantity: number;
	};
	skuSelected: {
		id: string;
		description: string;
		sku: string;
	};
	deliveryOrders: DeliveryOrder[];
	customerName?: string;
	customerAccurateId?: number;
	soAccurateId?: number;
	addressAccurate?: string;
	customerAccurateName: string;
	defaultCustomer?: CustomerAccurate;
	route?: any;
	scheduleBpId?: string;
}) {
	const params = useParams();
	const id = params?.id;
	const dispatch = useAppDispatch();
	const { bp } = useAppSelector((store) => store.batching);
	const { page } = useListSearchParams();
	const [payloadDO, setPayloadDO] = React.useState<PayloadDO>();
	const [loadingButton, setIsLoadingButton] = useState(false);

	const [optionTrucks, setOptionTrucks] = React.useState<any[]>([]);
	const [optionDrivers, setOptionDrivers] = React.useState<any[]>([]);
	const [customerPaging, setCustomerPaging] = React.useState(1);
	const [sorPaging, setSoPaging] = React.useState(1);
	const [shouldShowSealInput, setShouldShowSealInput] =
		React.useState<boolean>(true);
	const [selectedAddress, setSelectedAddress] = React.useState<{
		labelStr: string;
		valueStr: number | null;
	} | null>(null);

	const { isAccurateDisabled: accurateStatusDisabled } = useAppSelector((state) => state.setting);

	const isAccurateDoDisabled =
	process.env.REACT_APP_IS_ACCURATE_DISABLED === "true" || accurateStatusDisabled;

	const [searchCustomer, setSearchCustomer] = useState(customerAccurateName);
	const [searchSo, setSearchSo] = useState("");

	const [searchCustomerDebounced] = useDebounce(searchCustomer, 500);
	const [searchSoDebounced] = useDebounce(searchSo, 500);
	const [customersList, setCustomersList] = useState<CustomerAccurate[]>([]);

	const [batchingPlantId, setBatchingPlantId] = useState<string>("");
	const [moistureContent, setMoistureContent] = useState<object[]>([]);
	const [mcDate, setMcDate] = useState<string>("");
	const [bpName, setBpName] = useState<string>("");

	function formatName(name:string) {
		const formattedName = name
			?.replace(/^BP-/, "") // Remove "BP-" at the start
			.replace(/_/g, " ") // Replace underscores with spaces
			.toLowerCase() // Convert to lowercase first
			.split(" ") // Split into words
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
			.join(" "); // Join words back into a single string
		
		console.log('formattedName => ', formattedName)
		return formattedName
	}

	useEffect(() => {
		if (isOpen) {
			localDialog.onOpen();
		}
	}, [isOpen]);

	// Data detail Schedule
	const { isLoading, data } = useQuery<{ data: ScheduleResponse }, ApiError>(
		["query-schedule"],
		async () => {
			return await OrderService.getOneSchedule(id ?? "");
		},
		{
			enabled: true,
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	useEffect(() => {
		if (data) {
			const bpId = data?.data?.batchingPlantId;
			const initialPayload: PayloadDO = {
				scheduleId: {
					id: data.data.id as string,
					number: data.data.number as string
				},
				inputWoNumber: idWOSelected?.number.toString(),
				inputSku: `${skuSelected?.sku}`,
				quantity: idWOSelected.quantity,
				date: idWOSelected?.date.toString().split("T")[0] as string,
				addressAccurate: {
					valueStr: null,
					labelStr: addressAccurate || ""
				},
				volumeKumulatifAccurate:
					deliveryOrders.reduce((acc, curr) => {
						console.log(curr, "curr", idWOSelected.number);

						if (curr.number === idWOSelected.number) {
							return acc;
						}
						if (
							curr.status === "CANCELLED" ||
							curr.status === "REJECTED" ||
							curr.status === "DECLINED"
						) {
							return acc;
						}
						return acc + curr.quantity;
					}, 0) + idWOSelected.quantity
			};
			setPayloadDO(initialPayload);
			setBatchingPlantId(bpId as string);
		}
	}, [data]);

	useEffect(() => {
		if (addressAccurate) {
			setSelectedAddress({
				valueStr: null,
				labelStr: addressAccurate
			});
		}
	}, []);

	useEffect(() => {
		if (!payloadDO?.branch?.name || !route) return;

		const branch = payloadDO.branch.name;
		const tollFee =
			route.find(
				(r: { data?: { BatchingPlant?: { name?: string }; tollFee?: number } }) =>
					r.data?.BatchingPlant?.name &&
					formatName(r.data.BatchingPlant.name) === branch
			)?.data?.tollFee || 0;

		// Avoid unnecessary state updates
		setPayloadDO((prev) => {
			if ((prev ?? {}).biayaTolAccurate === tollFee) return prev;
			return { ...prev, biayaTolAccurate: tollFee };
		});
	}, [payloadDO?.branch, route]);

	useQuery<ApiDrivers, ApiError>(
		["query-driver", page],
		async () => {
			return await CommonService.getAllDrivers();
		},
		{
			enabled: true,
			onSuccess: (res) => {
				const data = res.data.map((el) => {
					return {
						...el,
						id: el.id,
						valueStr: el.id,
						labelStr: el.fullName
					};
				});
				setOptionDrivers(data);
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);
	useQuery<ApiVehicles, ApiError>(
		["query-vehicle", page],
		async () => {
			return await InventoryService.getAllVehicles();
		},
		{
			enabled: true,
			onSuccess: (res) => {
				const data = res.data.map((el) => {
					return {
						...el,
						id: el.id,
						text: `${el?.internalId ?? el?.internal_id} / ${
							el?.plateNumber ?? el?.plate_number
						}`,
						valueStr: el?.plateNumber ?? el?.plate_number,
						labelStr: `${el?.internalId ?? el?.internal_id} / ${
							el?.plateNumber ?? el?.plate_number
						}`
					};
				});
				setOptionTrucks(data);
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const { data: customersAccurate } = useQuery(
		["query-customer", customerPaging, searchCustomerDebounced],
		async () => {
			return await AccurateService.getAccurateCustomers({
				page: customerPaging,
				keywords: searchCustomerDebounced
			});
		},
		{
			onSuccess(data) {
				setCustomersList((prev) => {
					return prev.concat(data?.data?.d);
				});
				const defaultValueCustomer = data?.data?.d?.find(
					(cust: CustomerAccurate) => {
						return cust.id === customerAccurateId;
					}
				);

				if (defaultValueCustomer) {
					setPayloadDO((prev) => ({
						...prev,
						customerAccurate: defaultValueCustomer
					}));
				}
			},
			onError(err: any) {
				console.log(
					err?.response?.data?.error?.data?.error_description,
					"error log"
				);
				let message = "Accurate Error/ Tolong Login Ulang";

				if (err?.response?.data?.error?.data?.error) {
					message = `Accurate Error/ ${err?.response?.data?.error?.status} - ${err?.response?.data?.error?.data?.error}`;
				}
				dispatch(
					openAlert({
						body: message,
						color: "danger"
					})
				);
			},
			enabled: !isAccurateDoDisabled
		}
	);

	const {
		data: accurateCustomerDetails,
		isLoading: accurateCustomerDetailsLoading
	} = useQuery(
		["query-customer-details", payloadDO?.customerAccurate?.id],
		async () => {
			const res = await AccurateService.getAccurateCustomer({
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				id: payloadDO!.customerAccurate!.id
			});

			setSelectedAddress({
				valueStr: null,
				labelStr: res.data?.shipStreet || ""
			});
			setPayloadDO((prev) => ({
				...prev,
				addressAccurateText: res.data?.shipStreet || addressAccurate
			}));

			return {
				addresses: res.data?.detailShipAddress
					.map((address: any) => {
						return {
							labelStr: address.address,
							valueStr: address.id
						};
					})
					.concat([
						{
							labelStr: res.data.shipStreet,
							valueStr: null
						}
					]),
				shipStreet: res.data.shipStreet,
				branchId: res.data.branchId
			};
		},
		{
			enabled: !!payloadDO?.customerAccurate?.id,
			onError(err: any) {
				console.log(
					err?.response?.data?.error?.data?.error_description,
					"error log"
				);
				let message = "Accurate Error/ Tolong Login Ulang";

				if (err?.response?.data?.error?.data?.error) {
					message = `Accurate Error/ ${err?.response?.data?.error?.status} - ${err?.response?.data?.error?.data?.error}`;
				}
				dispatch(
					openAlert({
						body: message,
						color: "danger"
					})
				);
			}
		}
	);

	const { data: saleOrderDetail } = useQuery(
		["query-so-detail", payloadDO?.saleOrderAccurate],
		async () => {
			return await AccurateService.getAccurateSalesOrderDetail({
				id: payloadDO?.saleOrderAccurate?.id
			});
		},
		{
			enabled: !!payloadDO?.saleOrderAccurate,
			onError(err: any) {
				console.log(
					err?.response?.data?.error?.data?.error_description,
					"error log"
				);
				let message = "Accurate Error/ Tolong Login Ulang";

				if (err?.response?.data?.error?.data?.error) {
					message = `Accurate Error/ ${err?.response?.data?.error?.status} - ${err?.response?.data?.error?.data?.error}`;
				}
				dispatch(
					openAlert({
						body: message,
						color: "danger"
					})
				);
			}
		}
	);

	const { data: doNumbers } = useQuery(
		["query-do-numbers", bp.id],
		async () => {
			const res = await OrderService.getDONumbers(
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				bp.id!
			);

			setPayloadDO((prev) => {
				return {
					...prev,
					number: res.data.next
				};
			});
			return res.data;
		},
		{
			enabled: !!bp.id
		}
	);

	const { data: dispatchers } = useQuery(
		["query-dispatchers"],
		async () => {
			return await CommonService.getDispatchers({ size: 100 });
		},
		{
			enabled: true,
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const { data: branches } = useQuery(
		["query-branches"],
		async () => {
			return await AccurateService.getAccurateBranch({});
		},
		{
			onError(err: any) {
				console.log(
					err?.response?.data?.error?.data?.error_description,
					"error log"
				);
				let message = "Accurate Error/ Tolong Login Ulang";

				if (err?.response?.data?.error?.data?.error) {
					message = `Accurate Error/ ${err?.response?.data?.error?.status} - ${err?.response?.data?.error?.data?.error}`;
				}
				dispatch(
					openAlert({
						body: message,
						color: "danger"
					})
				);
			},
			enabled: !isAccurateDoDisabled
		}
	);
	const { data: warehouses } = useQuery(
		["query-warehouses"],
		async () => {
			return await AccurateService.getAccurateWarehouse({});
		},
		{
			enabled: !isAccurateDoDisabled,
			onError(err: any) {
				console.log(
					err?.response?.data?.error?.data?.error_description,
					"error log"
				);
				let message = "Accurate Error/ Tolong Login Ulang";

				if (err?.response?.data?.error?.data?.error) {
					message = `Accurate Error/ ${err?.response?.data?.error?.status} - ${err?.response?.data?.error?.data?.error}`;
				}
				dispatch(
					openAlert({
						body: message,
						color: "danger"
					})
				);
			}
		}
	);
	const { data: saleOrders } = useQuery(
		[
			"query-dispatch-driver",
			payloadDO?.customerAccurate,
			searchSoDebounced,
			sorPaging
		],
		async () => {
			return await AccurateService.getAccurateSalesOrder({
				customerId: payloadDO?.customerAccurate?.id,
				keywords: searchSoDebounced,
				page: sorPaging
			});
		},
		{
			enabled: !!payloadDO?.customerAccurate && !isAccurateDoDisabled,
			onSuccess(data) {
				const defaultValueSo = data?.data?.d?.find(
					(so: SaleOrderAccurate) => {
						return so.id === soAccurateId;
					}
				);
				if (defaultValueSo) {
					setPayloadDO((prev) => ({
						...prev,
						saleOrderAccurate: defaultValueSo,
						branch:
							branches?.data?.d?.find(
								(branch: AccurateBranch) => {
									return (
										branch.id === defaultValueSo?.branchId
									);
								}
							) || branches?.data?.d[0]
					}));
				}
			},
			onError(err: any) {
				console.log(
					err?.response?.data?.error?.data?.error_description,
					"error log"
				);
				let message = "Accurate Error/ Tolong Login Ulang";

				if (err?.response?.data?.error?.data?.error) {
					message = `Accurate Error/ ${err?.response?.data?.error?.status} - ${err?.response?.data?.error?.data?.error}`;
				}
				dispatch(
					openAlert({
						body: message,
						color: "danger"
					})
				);
			}
		}
	);
	const { data: drivers } = useQuery(
		["query-actual-driver"],
		async () => {
			return await OrderService.getActualDrivers();
		},
		{
			onError(err: any) {
				console.log(
					err?.response?.data?.error?.data?.error_description,
					"error log"
				);
				let message = "Accurate Error/ Tolong Login Ulang";

				if (err?.response?.data?.error?.data?.error) {
					message = `Accurate Error/ ${err?.response?.data?.error?.status} - ${err?.response?.data?.error?.data?.error}`;
				}
				dispatch(
					openAlert({
						body: message,
						color: "danger"
					})
				);
			}
		}
	);

	// get on moisture content by batching plant
	const { data: moistureContentData } = useQuery(
		["query-moisture-content", batchingPlantId],
		async () => {
			return await InventoryService.getOneMoistureContentByBp(
				batchingPlantId
			);
		},
		{
			enabled: true,
			onSuccess: (res) => {
				setMcDate(formatDateID(new Date(res?.data?.updatedAt)));
				setBpName(res?.data?.BatchingPlant?.name ?? "-");
				try {
					const moisutureData = [
						{
							name: "MC Pasir",
							data: `${res?.data?.mcPasir ?? "-"} %`
						},
						{
							name: "MC Pasir",
							data: `${res?.data?.mcAbuBatu ?? "-"} %`
						},
						{
							name: "MC Split",
							data: `${res?.data?.mcSplit ?? "-"} %`
						},
						{
							name: "MC Screening",
							data: `${res?.data?.mcScreening ?? "-"} %`
						}
					];
					setMoistureContent(moisutureData);
				} catch (error) {
					console.log(error);
				}
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const forms: ElementForm[] = React.useMemo(() => {
		const _form: ElementForm[] = [
			{
				label: "Tanggal",
				type: "date",
				value: payloadDO?.date,
				onChange: (e: ChangeEvent<HTMLInputElement>) =>
					setPayloadDO({
						...payloadDO,
						date: e.target.value
					}),
				placeholder: "Pilih Tanggal",
				min: true
			},
			{
				label: "Kuantitas (m³)",
				type: "number",
				value: payloadDO?.quantity,
				onChange: (e: ChangeEvent<HTMLInputElement>) =>
					setPayloadDO({
						...payloadDO,
						quantity: Number(e.target.value)
					}),
				placeholder: "Masukan Jumlah"
			},
			{
				label: "Truk",
				type: "dropdown",
				value: payloadDO?.truck,
				onChange: (e: any) => {
					setPayloadDO({
						...payloadDO,
						truck: e
					});
				},
				placeholder: "Pilih Truk",
				options: optionTrucks
			},
			{
				label: "HP TM",
				type: "dropdown",
				value: payloadDO?.driver,
				onChange: (e: any) => {
					setPayloadDO({
						...payloadDO,
						driver: e
					});
				},
				placeholder: "Pilih HP TM",
				options: optionDrivers
			},
			{
				label: "Nomor Seal Sama Dengan Nomor DO",
				type: "switch",
				value: shouldShowSealInput,
				onChange: (e: ChangeEvent<HTMLInputElement>) => {
					const newState = {
						...payloadDO,
						isSameSeal: e.target.checked
					};
					if (!shouldShowSealInput) {
						newState.sealNumber = "";
					}
					setPayloadDO(newState);
					setShouldShowSealInput(e.target.checked);
				}
			}
		];

		if (!shouldShowSealInput) {
			_form.push({
				label: "Nomor Seal",
				type: "text",
				value: payloadDO?.sealNumber,
				onChange: (e: ChangeEvent<HTMLInputElement>) =>
					setPayloadDO({
						...payloadDO,
						sealNumber: e.target.value
					}),
				placeholder: "Masukan Seal"
			});
		}

		return _form;
	}, [optionTrucks, payloadDO, optionDrivers, customersAccurate]);

	const accurateForm: ElementForm[] = React.useMemo(() => {
		const _form: ElementForm[] = [
			{
				label: "Nama Dispatcher",
				type: "dropdown",
				placeholder: "Ketik nama dispatcher",
				options: dispatchers?.data?.data?.map(
					(dispatcher: Dispatchers) => {
						return {
							valueStr: dispatcher.name,
							labelStr: dispatcher.name
						};
					}
				),
				value: payloadDO?.dispatcher,
				onChange: (e: any) => {
					setPayloadDO({
						...payloadDO,
						dispatcher: e
					});
				}
			},
			{
				// TODO: ensure backend has new logic for this
				label: "Nomor WO",
				type: "text",
				value: payloadDO?.inputWoNumber,
				placeholder: "WO",
				onChange: (e: ChangeEvent<HTMLInputElement>) => {
					setPayloadDO({
						...payloadDO,
						inputWoNumber: e.target.value
					});
				},
				disable: false
			},
			{
				// TODO: ensure backend has new logic for this
				label: "SKU" + " " + skuSelected?.description,
				type: "text",
				value: payloadDO?.inputSku,
				placeholder: "SKU",
				onChange: (e: ChangeEvent<HTMLInputElement>) => {
					setPayloadDO({
						...payloadDO,
						inputSku: e.target.value
					});
				},
				disable: false
			},
			// {
			// 	label: "Nama Driver Accurate",
			// 	type: "dropdown",
			// 	placeholder: "Ketik nama driver",
			// 	options: driverDispatch?.data?.d?.driver?.map(
			// 		(driver: string) => {
			// 			return {
			// 				valueStr: driver,
			// 				labelStr: driver
			// 			};
			// 		}
			// 	),
			// 	value: payloadDO?.driverAccurate,
			// 	onChange: (e: any) => {
			// 		setPayloadDO({
			// 			...payloadDO,
			// 			driverAccurate: e
			// 		});
			// 	}
			// },
			{
				label: "Jam Mulai Loading",
				type: "time",
				value: payloadDO?.loadingStartTime,
				disable: false,
				onChange: (e: ChangeEvent<HTMLInputElement>) => {
					setPayloadDO({
						...payloadDO,
						loadingStartTime: e.target.value
					});
				}
			},
			{
				label: "Jam Selesai Loading",
				type: "time",
				value: payloadDO?.loadingEndTime,
				disable: false,
				onChange: (e: ChangeEvent<HTMLInputElement>) => {
					setPayloadDO({
						...payloadDO,
						loadingEndTime: e.target.value
					});
				}
			}
		];
		return _form;
	}, [customersAccurate, dispatchers, payloadDO]);

	const accurateSecond: ElementForm[] = React.useMemo(() => {
		return [
			{
				label: "Biaya Mobilisasi (IDR)",
				type: "number",
				value: payloadDO?.biayaMobilisasiAccurate,
				disable: false,
				onChange: (e: ChangeEvent<HTMLInputElement>) => {
					setPayloadDO({
						...payloadDO,
						biayaMobilisasiAccurate: Number(e.target.value)
					});
				}
			},
			{
				label: "Volume Kumulatif (m³)",
				type: "number",
				value: payloadDO?.volumeKumulatifAccurate,
				disable: false,
				onChange: (e: ChangeEvent<HTMLInputElement>) => {
					setPayloadDO({
						...payloadDO,
						volumeKumulatifAccurate: Number(e.target.value)
					});
				}
			},
			{
				label: "Biaya Tol (IDR)",
				type: "number",
				value: payloadDO?.biayaTolAccurate,
				disable: false,
				onChange: (e: ChangeEvent<HTMLInputElement>) => {
					setPayloadDO({
						...payloadDO,
						biayaTolAccurate: Number(e.target.value)
					});
				}
			},
			{
				label: "Jarak Pengiriman (KM)",
				type: "number",
				value: payloadDO?.jarakPengirimanAccurate,
				disable: false,
				onChange: (e: ChangeEvent<HTMLInputElement>) => {
					setPayloadDO({
						...payloadDO,
						jarakPengirimanAccurate: Number(e.target.value)
					});
				}
			}
		];
	}, [payloadDO]);

	const [localDialog, setLocalDialog] = React.useState({
		isOpen: false,
		onClose: () => setLocalDialog({ ...localDialog, isOpen: false }),
		onOpen: () => setLocalDialog({ ...localDialog, isOpen: true })
	});

	const { mutateAsync: createDo } = useMutation(
		async (payload: PayloadCreateDO & Partial<PayloadCreateAccurateDO>) => {
			return await OrderService.createDO(payload);
		},
		{
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
				setIsLoadingButton(false);
			}
		}
	);

	const { mutateAsync } = useMutation(
		async (payload: Partial<deliveryOrderArgAccurate>) => {
			return await AccurateService.createDeliveryOrder({ payload });
		},
		{
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data?.message,
						color: "danger"
					})
				);
				setIsLoadingButton(false);
			}
		}
	);

	const { mutateAsync: updateDo, isLoading: updateLoading } = useMutation(
		async (payload: PayloadCreateDO) => {
			return await OrderService.updateDO(payload.id as string, payload);
		},
		{
			onSuccess: (response) => {
				refetch();
				localDialog.onClose();
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const onSubmit = async () => {
		try {
			console.log(
				isAccurateDoDisabled,
				"isAccurateDoDisabled",
				process.env.REACT_APP_IS_ACCURATE_DISABLED
			);
			setIsLoadingButton(true);
			if (!payloadDO?.quantity || Number(payloadDO?.quantity) <= 0) {
				dispatch(
					openAlert({
						body: "masukan jumlah DO",
						color: "danger"
					})
				);
				return;
			}

			if (!payloadDO?.customerAccurate && !isAccurateDoDisabled) {
				dispatch(
					openAlert({
						body: "pilih pelanggan accurate",
						color: "danger"
					})
				);
				return;
			}
			if (!payloadDO?.date) {
				dispatch(
					openAlert({
						body: "pilih tanggal DO",
						color: "danger"
					})
				);
				return;
			}
			if (!payloadDO?.saleOrderItem && !isAccurateDoDisabled) {
				dispatch(
					openAlert({
						body: "pilih barang SO",
						color: "danger"
					})
				);
				return;
			}

			if (!payloadDO?.dispatcher && !isAccurateDoDisabled) {
				dispatch(
					openAlert({
						body: "pilih dispatcher",
						color: "danger"
					})
				);
				return;
			}
			if (!payloadDO?.actualDriver) {
				dispatch(
					openAlert({
						body: "pilih driver",
						color: "danger"
					})
				);
				return;
			}

			const createDoPayload: PayloadCreateDO &
				Partial<PayloadCreateAccurateDO> = {
				scheduleId: payloadDO?.scheduleId?.id as string,
				date: payloadDO?.date,
				quantity: payloadDO?.quantity as number,
				driverId: payloadDO?.driver?.id as string,
				vehicleId: payloadDO?.truck?.id as string,
				batchingPlantId: bp?.valueStr,
				...(payloadDO?.number && {
					number: payloadDO.number
				}),
				inputSku: skuSelected?.id as string,
				inputWoNumber: payloadDO?.inputWoNumber as string,
				workOrderId: idWOSelected?.id,
				actualDriver: payloadDO?.actualDriver?.__isNew__
					? {
							name: payloadDO?.actualDriver.label
					  }
					: {
							...payloadDO?.actualDriver,
							id: payloadDO?.actualDriver?.userId
					  },
				actualDriverId: payloadDO?.actualDriver.id,
				// Accurate fields
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				customerAccurateId: payloadDO?.customerAccurate?.id,
				saleOrderAccurateId: payloadDO?.saleOrderAccurate?.id as number,
				addressAccurate: payloadDO?.addressAccurateText as string,
				addressAccurateId: payloadDO?.addressAccurate?.valueStr || null,
				branchId: accurateCustomerDetails?.branchId,
				// deliveryOrderAccurateId: data?.data?.r?.id,
				accurateEndLoadingTime: payloadDO?.loadingEndTime as string,
				accurateStartLoadingTime: payloadDO?.loadingStartTime as string
			};

			if (payloadDO.sealNumber) {
				createDoPayload.sealNumber = payloadDO.sealNumber;
			} else if (payloadDO.number && !payloadDO.sealNumber) {
				createDoPayload.sealNumber = payloadDO.number;
			}

			const createdDo = await createDo(createDoPayload);
			console.log(createdDo, "createdDo");

			if (
				//
				!isAccurateDoDisabled
			) {
				const inputDateString = payloadDO.date;

				// Create a Date object from the input string
				const inputDate = new Date(inputDateString);

				// Extract day, month, and year components
				const day = inputDate.getDate();
				const month = inputDate.getMonth() + 1; // Months are zero-based, so add 1
				const year = inputDate.getFullYear();

				// Format the date in the desired format
				const formattedDate = day + "/" + month + "/" + year;

				const accurateDoPayload: Partial<deliveryOrderArgAccurate> = {
					number: payloadDO?.number,
					toAddress: payloadDO?.addressAccurateText,
					customerNo: payloadDO.customerAccurate?.customerNo,
					transDate: formattedDate,
					itemNo: payloadDO?.saleOrderItem?.item?.no,
					quantity: payloadDO?.quantity,
					salesOrderNumber: payloadDO?.saleOrderAccurate?.number,
					noTm: payloadDO?.truck?.labelStr,
					driver: payloadDO?.actualDriver?.label,
					startLoadingTime: payloadDO?.loadingStartTime,
					endLoadingTime: payloadDO?.loadingEndTime,
					dispatcher: payloadDO?.dispatcher?.valueStr,
					skuNo: payloadDO?.inputSku,
					woNo: payloadDO?.inputWoNumber,
					cumulativeVolume: payloadDO?.volumeKumulatifAccurate,
					mobilizationFee: payloadDO?.biayaMobilisasiAccurate,
					deliveryDistance: payloadDO?.jarakPengirimanAccurate,
					tollFee: payloadDO?.biayaTolAccurate,
					warehouseName: payloadDO?.warehouse?.name,
					branchId: payloadDO?.branch?.id
				};

				const data = await mutateAsync(accurateDoPayload);

				if (!data?.data?.s) {
					throw data?.data?.d;
				}
				const doId = data?.data?.r?.id;

				await updateDo({
					id: createdDo?.data[0].id,
					deliveryOrderAccurateId: doId,
					accurateData: {
						...accurateDoPayload,
						customerAccurate: payloadDO.customerAccurate,
						itemSalesOrder: payloadDO?.saleOrderItem,
						salesOrderAccurate: payloadDO?.saleOrderAccurate,
						warehouse: payloadDO?.warehouse,
						branch: payloadDO?.branch,
						address: payloadDO?.addressAccurateText,
						detailItemId: data?.data.r?.detailItem[0]?.id
					}
				});
			}
			refetch();
			localDialog.onClose();

			dispatch(
				openAlert({
					body: "Berhasil Membuat Delivery Order",
					color: "success"
				})
			);
			setIsOpen(false);
		} catch (error: any) {
			console.log(error, "error accurate");
			if (error.length) {
				dispatch(
					openAlert({
						body: error?.join(","),
						color: "danger"
					})
				);
				refetch();
				localDialog.onClose();
				setIsOpen(false);
			} else {
				dispatch(
					openAlert({
						body: error.response?.data.message,
						color: "danger"
					})
				);
			}
		} finally {
			setIsLoadingButton(false);
		}
	};

	const Option = (props: OptionProps<CustomerAccurate>) => {
		return (
			<div className={styles.selectCustom}>
				{props.data.customerNo}
				<components.Option {...props} />
				<p className={styles.selectInfo}>
					HP: <b>{props.data.mobilePhone}</b>
					WA:<b>{props.data.whatsappNo}</b>
					email: <b>{props.data.email}</b>
				</p>
			</div>
		);
	};
	return (
		<LocalDialog
			isOpen={localDialog.isOpen}
			backdrop={"static"}
			header={`Buat Delivery Order / ${payloadDO?.scheduleId?.number}`}
			loading={loadingButton}
			onClick={onSubmit}
			onClose={() => {
				localDialog.onClose();
				setTimeout(() => {
					setIsOpen(false);
				}, 500);
			}}
			bodyStyle={{
				width: "1000px"
			}}
		>
			<div>
				{/* nama pelanggan nya */}
				<p>
					Nama Pelanggan: <b>{customerName}</b>
				</p>
				<Row>
					<Col md="12">
						<label>
							Pelanggan Accurate
							<span
								style={{
									color: "red",
									marginLeft: "5px"
								}}
							>
								*
							</span>
						</label>

						<ReactSelect
							options={customersList}
							components={{ Option }}
							getOptionLabel={(option: CustomerAccurate) =>
								option.name
							}
							getOptionValue={(option: CustomerAccurate) =>
								option.customerNo
							}
							onMenuScrollToBottom={() => {
								if (
									customersAccurate?.data?.sp?.pageCount >
									customerPaging
								) {
									setCustomerPaging((prev) => prev + 1);
									setSearchCustomer("");
								}
							}}
							onInputChange={(inputValue: string) => {
								setSearchCustomer(inputValue);
							}}
							onChange={(e) => {
								setPayloadDO((prev) => {
									return {
										...prev,
										addressAccurate: null,
										saleOrderAccurate: null,
										saleOrderItem: null,
										customerAccurate: e
									};
								});
							}}
							isMulti={false}
							required
							defaultValue={defaultCustomer}
							isDisabled={isAccurateDoDisabled}
						/>
					</Col>
				</Row>
				<Spacer size="small" />

				<Row>
					<Col md="12">
						<label>Alamat</label>
						<BDropdown
							type="dropdown"
							label="Alamat Pengiriman"
							isDisabled={
								!payloadDO?.customerAccurate ||
								isAccurateDoDisabled
							}
							options={accurateCustomerDetails?.addresses || []}
							onChange={(e: any) => {
								setSelectedAddress(e);
								setPayloadDO((prev) => {
									return {
										...prev,
										addressAccurateText: e.labelStr
									};
								});
							}}
							isMulti={false}
							placeholder={`${
								accurateCustomerDetails?.addresses
									? accurateCustomerDetails?.addresses?.length
									: 0
							} Pilihan Alamat`}
							defaultValue={`${
								accurateCustomerDetails?.addresses
									? accurateCustomerDetails?.addresses?.length
									: 0
							} Pilihan Alamat`}
							value={
								selectedAddress ? selectedAddress.labelStr : ""
							}
						/>
					</Col>
				</Row>
				<Spacer size="small" />

				<Row>
					<Col md="12">
						<label>
							Alamat Pengiriman
							<span
								style={{
									color: "red",
									marginLeft: "5px"
								}}
							>
								*
							</span>
						</label>
						{accurateCustomerDetailsLoading ? (
							<div
								style={{
									width: "100%",
									height: "100px",
									display: "flex",
									justifyContent: "center",
									alignItems: "center"
								}}
							>
								<LoadingSpinner />
							</div>
						) : (
							<Input
								type="textarea"
								value={payloadDO?.addressAccurateText}
								disabled={
									!payloadDO?.customerAccurate &&
									!(
										payloadDO?.addressAccurate &&
										accurateCustomerDetails?.addresses
											.length > 0
									)
								}
								rows={4}
								onChange={(e) => {
									// setSelectedAddress((prev) => ({
									// 	valueStr: prev?.valueStr || null,
									// 	labelStr: e.target.value
									// }));
									setPayloadDO((prev) => {
										return {
											...prev,
											addressAccurateText: e.target.value
										};
									});
								}}
							/>
						)}
					</Col>
				</Row>
				<Spacer size="small" />
				<Row>
					<Col md="12">
						<label>
							Sale Order / Pesanan Accurate
							<span
								style={{
									color: "red",
									marginLeft: "5px"
								}}
							>
								*
							</span>
						</label>
						<ReactSelect
							options={saleOrders?.data?.d || []}
							value={payloadDO?.saleOrderAccurate}
							getOptionLabel={(option: SaleOrderAccurate) =>
								option.number
							}
							getOptionValue={(option: SaleOrderAccurate) =>
								option.number
							}
							onChange={(e) => {
								setPayloadDO((prev) => {
									return {
										...prev,
										saleOrderAccurate: e,
										saleOrderItem: null,
										branch:
											branches?.data?.d?.find(
												(branch: AccurateBranch) => {
													return (
														branch.id ===
														payloadDO
															?.saleOrderAccurate
															?.branchId
													);
												}
											) || branches?.data?.d[0]
									};
								});
							}}
							isMulti={false}
							// value={payloadDO?.customerAccurate}
							isDisabled={!payloadDO?.customerAccurate}
							required
							onInputChange={(input: string) => {
								setSearchSo(input);
							}}
							onMenuScrollToBottom={() => {
								if (
									saleOrders?.data?.sp?.pageCount > sorPaging
								) {
									setSoPaging((prev) => prev + 1);
								}
							}}
						/>
					</Col>
				</Row>
				<Spacer size="small" />

				<Row>
					<Col md="12">
						<label>
							Barang Sales Order
							<span
								style={{
									color: "red",
									marginLeft: "5px"
								}}
							>
								*
							</span>
						</label>
						<ReactSelect
							options={saleOrderDetail?.data?.d?.detailItem || []}
							value={payloadDO?.saleOrderItem}
							getOptionLabel={(option: DetailItem) =>
								option.item.no +
								" - " +
								option.item.name +
								" / " +
								`${option.availableQuantity} M³`
							}
							getOptionValue={(option: DetailItem) =>
								option.item.no
							}
							onChange={(e) => {
								setPayloadDO((prev) => {
									return {
										...prev,
										saleOrderItem: e,
										warehouse:
											warehouses?.data?.d?.find(
												(
													warehouse: AccurateWarehouse
												) => {
													return (
														warehouse.id ===
														payloadDO?.saleOrderItem
															?.warehouseId
													);
												}
											) || warehouses?.data?.d[0]
									};
								});
							}}
							isMulti={false}
							// value={payloadDO?.customerAccurate}
							isDisabled={!payloadDO?.saleOrderAccurate}
							required
						/>
					</Col>
				</Row>
				<Spacer size="small" />

				<Row>
					<Col md="12">
						{payloadDO?.saleOrderItem ? (
							<>
								<label>
									Sale Order / Pesanan Accurate Terpilih
								</label>
								<div>
									<p>
										Status:{" "}
										<b>
											{
												saleOrderDetail?.data?.d
													?.statusName
											}
										</b>
									</p>
									<p>
										No. PO:
										<b>
											{payloadDO?.saleOrderItem
												?.salesOrderPoNumber || "-"}
										</b>
									</p>
									<p>
										Kuantitas Tersedia Untuk Dikirim :{" "}
										<b>
											{
												payloadDO?.saleOrderItem
													?.availableQuantity
											}
										</b>{" "}
										M³ /{payloadDO?.saleOrderItem?.quantity}{" "}
										M³
									</p>
									<label>Gudang</label>
									<ReactSelect
										options={warehouses?.data?.d || []}
										defaultValue={
											warehouses?.data?.d?.find(
												(
													warehouse: AccurateWarehouse
												) => {
													return (
														warehouse.id ===
														payloadDO?.saleOrderItem
															?.warehouseId
													);
												}
											) || warehouses?.data?.d[0]
										}
										getOptionLabel={(
											option: AccurateWarehouse
										) => option.name}
										getOptionValue={(
											option: AccurateWarehouse
										) => option.id.toString()}
										onChange={(e) => {
											setPayloadDO((prev) => {
												return {
													...prev,
													warehouse: e
												};
											});
										}}
										isMulti={false}
										value={payloadDO?.warehouse}
										// isDisabled={
										// 	!payloadDO?.saleOrderAccurate
										// }
									/>
								</div>
							</>
						) : null}
					</Col>
				</Row>
				<Spacer size="small" />

				<Row>
					<Col md="12">
						<label>Nomor DO</label>
						<Input
							type="text"
							value={
								payloadDO?.number
									? payloadDO?.number
									: undefined
							}
							placeholder="Kosongkan jika auto"
							disabled={!doNumbers}
							rows={4}
							onChange={(e) => {
								setPayloadDO((prev) => {
									return {
										...prev,
										number: e.target.value
									};
								});
							}}
						/>
					</Col>
				</Row>

				<Spacer size="small" />

				<Row
					style={{
						display: isAccurateDoDisabled ? "none" : "unset"
					}}
				>
					<Col md="12">
						<label>
							Cabang / BP
							<span
								style={{
									color: "red",
									marginLeft: "5px"
								}}
							>
								*
							</span>
						</label>
						{payloadDO?.saleOrderAccurate ? (
							<ReactSelect
								options={branches?.data?.d || []}
								value={payloadDO?.branch}
								defaultValue={
									branches?.data?.d?.find(
										(branch: AccurateBranch) => {
											return (
												branch.id ===
												payloadDO?.saleOrderAccurate
													?.branchId
											);
										}
									) || branches?.data?.d[0]
								}
								getOptionLabel={(option: AccurateBranch) =>
									option.name
								}
								getOptionValue={(option: AccurateBranch) =>
									option.id.toString()
								}
								onChange={(e) => {
									setPayloadDO((prev) => {
										return {
											...prev,
											branch: e
										};
									});
								}}
								isMulti={false}
								required
							/>
						) : null}
					</Col>
				</Row>
				<Spacer size="small" />

				<Row>
					<Col md="12">
						<label>
							Driver
							<span
								style={{
									color: "red",
									marginLeft: "5px"
								}}
							>
								*
							</span>
						</label>
						<Creatable
							isClearable
							options={
								drivers?.data?.map((driver: ActualDrivers) => {
									return {
										...driver,
										label: driver.name,
										value: driver.id
									};
								}) || []
							}
							onChange={(e) => {
								console.log(e, "ini created");

								setPayloadDO((prev: any) => {
									return {
										...prev,
										actualDriver: e
									};
								});
							}}
							isMulti={false}
							required
						/>
					</Col>
				</Row>
				<Spacer size="small" />

				<BForm forms={accurateForm} />
				<div
					style={{
						display: "flex",
						gap: "40px"
					}}
				>
					<div
						style={{
							flex: 1
						}}
					>
						<BForm forms={forms} />
					</div>
					<div
						style={{
							flex: 1
						}}
					>
						<BForm forms={accurateSecond} />
					</div>
				</div>

				{/* Moisture Content */}
				<div
					style={{
						maxWidth: "450px"
					}}
				>
					<div
						style={{
							backgroundColor: "#f5f5f5",
							// border: "1px solid black",
							borderRadius: "0.3rem",
							padding: "0.5rem"
						}}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								gap: "0.2rem"
							}}
						>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									marginBottom: "1rem"
								}}
							>
								<span style={{ fontSize: "20px" }}>
									<strong>Moisture Content {mcDate}</strong>
								</span>
								<span>{bpName}</span>
							</div>

							<div
								style={{
									display: "flex",
									justifyContent: "space-between"
								}}
							>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										gap: "0.1rem"
									}}
								>
									<span>MC Pasir</span>
									<span>MC Abu Batu</span>
									<span>MC Split</span>
									<span>MC Screening</span>
								</div>

								<div
									style={{
										display: "flex",
										flexDirection: "column",
										gap: "0.1rem"
									}}
								>
									<span>
										{`${moistureContentData?.data?.mcPasir} %` ??
											"-"}
									</span>
									<span>
										{`${moistureContentData?.data?.mcAbuBatu} %` ??
											"-"}
									</span>
									<span>
										{`${moistureContentData?.data?.mcSplit} %` ??
											"-"}
									</span>
									<span>
										{`${moistureContentData?.data?.mcScreening} %` ??
											"-"}
									</span>
								</div>
							</div>
						</div>
					</div>

					{/* <BCard data={moistureContent} /> */}
				</div>
			</div>
		</LocalDialog>
	);
}
